<template>
  <div>
    <v-card width="500" class="mb-2">
      <v-system-bar>Klassendokumente</v-system-bar>
      <v-list>
        <ReportItem
          v-for="item in items"
          :key="item.id"
          :value="item"
          :id="schoolClass.id"
        />
      </v-list>
    </v-card>

    <v-card width="500">
      <v-system-bar>weiteres</v-system-bar>
      <ReportItemLocal
        resource="report/exams"
        icon="mdi-file-excel-box"
        color="green"
        :parameters="{ type: 'class', id: schoolClass.id }"
        >Probenplan</ReportItemLocal
      >
      <v-list-item
        color="info"
        :to="{ name: 'ICalendar' }"
        v-if="
          true ||
          ($_hasRole('student') &&
            schoolClass.id == $_profilePerson.schoolClass.id)
        "
      >
        <v-list-item-icon
          ><v-icon left>mdi-calendar-sync</v-icon></v-list-item-icon
        >
        <v-list-item-title>Kalender-Abo</v-list-item-title>
        <v-list-item-action><v-icon>mdi-link</v-icon></v-list-item-action>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ReportItem from "common/components/ReportItem.vue";
import ReportItemLocal from "@/components/ReportItem.vue";

export default defineComponent({
  name: "SchoolClassDocuments",
  props: ["schoolClass"],
  components: { ReportItem, ReportItemLocal },
  data() {
    return {
      id: Number,
      items: [],
      loading: false,
    };
  },
  watch: {
    schoolClass() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.schoolClass) {
        return;
      }
      this.loading = true;
      this.items = await this.apiList({
        resource: "app/report",
        query: "type=schoolClass",
      });
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
